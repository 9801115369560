.navbar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  background-color: #DC6266;
}

.navbar-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

/* Add padding to bring the content (logo and Home button) away from the extreme left */
.container-fluid {
  padding-left: 20px; /* Adjust this value to control the spacing */
}

.navbar-nav {
  margin-left: 15px; /* Adds space between the Home button and the logo */
}

.navbar-brand {
  margin-right: 20px; /* Add space between the logo and the nav items */
}
