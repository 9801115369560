Form {
  /* border: 2px solid; */
  /* padding: 0px ;
  width: 100% ; */
}
.btn_submit {
  padding: 7px 28px;
  background-color: #ff5c5c !important;
  border: none;
  outline: none;
  border-radius: 10px;
}
.btn_div1 {
  text-align: center;
  padding: 10px;
}
.btn_div1 Button {
  background-color: #ff5c5c;
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  outline: none;
}
.react-date-picker__wrapper {
  /* border: 1px solid #ced4da; */
  padding: 5px;
  width: 100%;
  border-radius: 5px;
}
.react-date-picker.react-date-picker--closed.react-date-picker--enabled {
  width: 100%!important;
}
.form-control.borderinput{
  border: none!important;
    border-bottom: 2px solid #000!important;
    border-radius: 0px!important;
}
.date-picker.borderinput{
  border: none!important;
    border-bottom: 2px solid #000!important;
    border-radius: 0px!important;
    
}
@media only screen and (max-width: 600px) {
  .date-picker.borderinput {
    margin-top: 15px;
  }
  i.fa.fa-envelope.cercle-2 {
    position: relative;
    top: 52px;
    left: 2px;
    padding: 12px;
  
}
i.fa.fa-lock.cercle-2 {
  position: relative;
  top: 52px;
  left: 2px;
  padding: 12px;
}
}

.react-date-picker__wrapper{border: none!important;}
.left-right{text-align: right;}
.left-left{text-align: left;}
.left-left button{padding: 4px 28px !important;
  background-color: #807e7e !important;
  border: none;
  outline: none;
  border-radius: 25px !important;}
  button.mybtn.btn.btn-primary {
    background-color: #595858;
    border: none;
    border-radius: 25px;
    padding: 10px 30px;
}
button.mybtn{
  background-color: #595858;
  border: none;
  border-radius: 25px;
  padding: 5px 30px;
  color: white;
}
button.mybtn:hover{
  background-color: #595858!important;
}
button.mybtn .active{
  background-color: #595858!important;
}
button.mybtn:focus{
  background-color: #595858!important;
}
button.mybtn:visited{
  background-color: #595858!important;
}

.text-bold{font-weight: bold;}
.text-d{
  position: relative;
  top: -7px;
}