.login_wrapper {
  width: 60%;
  margin: auto;
  display: flex;
  box-shadow: 1px 1px 8px 0px;
  border-radius: 10px;
  padding: 50px 25px;
  justify-content: space-between;
  height: fit-content;
}
.login_logo {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid lightgray;
}
.login_logo img {
  /* width: 250px;
  height: 250px; */
  border-radius: 15px;
}
.login_form {
  width: 60%;
  padding: 10px 50px;
  /* border: 2px solid red; */
}
#pass_div {
  /* border: 2px solid; */
}
#forgot_pass a {
  text-decoration: none;
  color: #ff5c5c;
  font-size: 14px;
}
.btn_div {
  width: 90%;
  display: flex;
  /* border: 2px solid red; */
  justify-content: space-around;
  align-items: center;
}
.btn_div button {
  color: white;
}
.btn_div Button a {
  color: white;
  text-decoration: none;
}
.btn_div Button {
  padding: 7px 28px;
  background-color: #ff5c5c;
  border: none;
  outline: none;
  border-radius: 10px;
}
.btn_div Button:hover {
  padding: 7px 28px;
  background-color: #ff5c5c;
  border: none;
  outline: none;
  border-radius: 10px;
}
.btn-primary:focus {
  /* padding: 7px 28px; */
  padding: 0px 12px !important;
  background-color: #807e7e !important;
  border: none !important;
  outline: none !important;
  border-radius: 10px;
}
.component_wrapper {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#qr_class {
  height: 50vh;
  text-align: center;
  width: 100%;
}
.mb-3 {
  /* width: 90%; */
  width: 100%;
}
Form {
  /* border: 2px solid; */
  width: 80%;
  margin: auto;
}
.form-label {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.modal-footer .btn-primary {
  background-color: #595858;
  border: none;
  outline: none;
}
.modal-title {
  color: #807e7e;
}
.spinner-border {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid currentColor !important;
  border-right-color: transparent !important;
  margin-left: 10px;
}
.login_text {
  display: flex;
  align-items: center;
}

#form-apply {
  font-weight: bolder;
}

#logout-div {
  /* border: 3px solid green; */
  float: right;
  background-color: #ff5c5c;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  padding: 8px;
  font-size: 18px;
  transform: translate(5px, -33px);
}
#log {
}
#log1 {
  width: 70%;
}
@media (min-width: 320px) and (max-width: 480px) {
  #log1 {
    width: 100% !important;
    height: 55% !important;
  }
  canvas {
    height: 80% !important;
  }
  #logout-div {
    position: absolute;
    bottom: 0%;
    right: 40%;
  }
}
