.login_wrapper {
  width: 60%;
  margin: auto;
  display: flex;
  /* flex-wrap: wrap; */
  box-shadow: 1px 1px 8px 0px;
  border-radius: 10px;
  padding: 50px 25px;
  justify-content: space-between;
  height: 60vh;

}
.login_logo {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-right: 1px solid lightgray; */
}
.login_logo img {
  width: 220px;
  height: 220px;
  border-radius: 15px;
}
.login_form {
  width: 60%;
  padding: 10px 50px;
}
#forgot_pass a {
  text-decoration: none;
  color: #595858 !important;
  font-size: 14px;
  cursor: pointer;
}
.btn_div {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.btn_div button {
  color: white;
}
.btn_div Button a {
  color: white;
  text-decoration: none;
}
.btn_div Button {
  padding: 4px 12px !important;
  background-color: #807e7e !important;
  border: none;
  outline: none;
  border-radius: 25px;
}
.btn_div Button:hover {
  padding: 4px 28px; 
  background-color: #807e7e !important;
  border: none;
  outline: none;
  border-radius: 25px;
}
.btn-primary:focus {
  padding: 0px 12px !important;
  background-color: #807e7e !important;
  border: none !important;
  outline: none !important;
  border-radius: 25px;
}
.component_wrapper {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mb-3 {
  width: 90%;
}
.width-100{
  width: 100% !important;
}
Form {
  width: 80%;
  margin: auto;
}
.form-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.modal-footer .btn-primary {
  background-color: #807e7e;
  border: none;
  outline: none;
}
.modal-title {
  color: #ff5c5c;
}
.spinner-border {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid currentColor !important;
  border-right-color: transparent !important;
  margin-left: 10px;
}
.login_text {
  display: flex;
  align-items: center;
}
.login_css {
  width: 100%;
  /* height: 50vh; */
}
form.login_form_front input {
  text-align: center;
  height: 60px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .login_wrapper {
    padding: 25px !important;
    flex-wrap: wrap;
    width: 90% !important;
    /* height: 95vh !important; */
  }
  .left-right {
    padding-right: 0;
}
  .login_logo {
    /* width: 100% !important; */
    width: 80% !important;
    border: none !important;
    margin-bottom: 10px;
    height: 40%;
  }
  .login_logo img {
    width: 80% !important;
    height: 100% !important;
  }
  Form {
    width: 100% !important;
    padding: 0 !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .component_wrapper {
    width: 100% !important;
    height: 55%;
  }
  .btn_div {
    width: 100% !important;
  }
  .mb-3 {
    width: 100% !important;
  }
  .Lb_login_form {
    padding: 0.6rem 0.75rem !important;
    font-size: 20px !important;
}
.btn_div Button span {
  font-size: 20px !important;
}
}
@media (min-width: 481px) and (max-width: 768px) {
  form.login_form_front input {
    
    height: 49px!important
  }
  .login_wrapper {
    padding: 25px !important;
    flex-wrap: wrap;
    width: 90% !important;
    height: 170vh !important;
  }
  .login_logo {
    width: 80% !important;
    border: none !important;
    /* margin-bottom: 10px; */
    /* height: 40%; */
  }
  .login_logo img {
    width: 80% !important;
    /* height: 100% !important; */
  }
  Form {
    width: 100% !important;
    padding: 0 !important;
    height: 100% !important;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .component_wrapper {
    width: 100% !important;
    height: 55%;
  }
  .btn_div {
    width: 100% !important;
  }
  .mb-3 {
    width: 100% !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .login_wrapper {
    width: 100% !important;
  }
  .login_logo img {
    width: 80% !important;
  }
  .login_logo {
    width: 80%!important;
  }
  .component_wrapper {
    width: 60% !important;
    padding: 10px !important;
  }
  .btn_div {
    width: 100% !important;
  }
  .mb-3 {
    width: 100% !important;
  }
  Form {
    width: 100% !important;
    padding: 0px !important;
  }
 
}
.login_wrapper {
  box-shadow: none !important;
}
form.login_form_front input {
  text-align: center;
  height: 60px;
}
.btn_div Button {
  padding: 4px 28px !important;
    background-color: #807e7e !important;
    border: none;
    outline: none;
    border-radius: 25px !important;
    width: 70%;
}
.btn_div Button span {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.2px;
  font-size: 23px;
}
.lb_rg_btn{
  display: block-inline;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 12px;
  border: none;
  border-radius: 50px;
  text-align: center !important;
  background-color: #595858 !important;
  color: #fff;
}
.Lb_login_form{
  display: block;
    width: 100%;
    padding: 0.8rem 0.75rem;
    font-size: 23px;
    font-weight: 100;
    line-height: 1.5;
    color: #000;
    background-color: #f1f0f0;
    background-clip: padding-box;
    border: 0px solid #f1f0f0;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 3rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    /* margin-bottom: 14px; */
}
.form-control:focus {
  color: #212529;
  background-color: #f1f0f0 !important;
  border-color: #f1f0f0 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgb(242 242 242 / 25%);
}
.login_logo {
  border-right: none !important;
  margin-bottom: 50px !important;
  margin: auto !important;
  margin-bottom: 50px!important;
}
.login_wrapper{
  display: block !important;
}

.component_wrapper{
  width: 80% !important;
  margin: auto !important;
}
.login_logo img {
  width: 70%;
  height: auto !important;
}
/* .login_logo {
  width: 50% !important;
} */

i.fa.fa-envelope.cercle-2 {
  border: 2px solid #000;
    padding: 20px;
    border-radius: 50px;
    position: relative;
    top: 60px;
    left: 1px;
    background-color: #f1f0f0;
}
i.fa.fa-lock.cercle-2 {
  border: 2px solid #000;
    padding: 20px;
    border-radius: 50px;
    position: relative;
    top: 60px;
    left: 1px;
    background-color: #f1f0f0;
}

