.footer {
  background-color: #DC6266;
  padding: 20px 0px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
}

/* Footer Text and Links */
.footer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-text p {
  margin: 0;
  color: white; /* Make the text white */
  font-size: 14px;
}

.footer-text p strong {
  font-weight: bold; /* Make "Lesbian Adventures in Love." bold */
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
  margin-left: 0; /* Remove default margin */
  display: flex;
  justify-content: flex-start; /* Align the link to the left */
  width: 100%; /* Ensure it takes full width */
}

.footer-links li {
  display: inline-block;
  margin: 0;
}

.footer-links a {
  color: white; /* Make the links white */
  text-decoration: none;
  font-size: 16px;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Social Media Icons */
.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.social-media a {
  color: white; /* Make the icons white */
  margin: 0 15px;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #007bff; /* Change color on hover */
}

/* Responsive styling */
@media (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: space-between; /* Space between copyright/links and social media on larger screens */
  }

  .footer-content {
    flex-direction: row;
    align-items: center;
  }

  .social-media {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .footer-text {
    margin-bottom: 20px;
    
  }

  .footer-links {
    margin-bottom: 20px; 
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align child elements */
    justify-content: center;
    text-align: center;
  }

  .social-media-container {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-left: 20px;
  }

  .social-media {
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center; /* Center the icons horizontally */
    align-items: center; 
  }
}
