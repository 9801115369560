Form {
  /* border: 2px solid; */
  padding: 20px;
}
.btn_submit {
  padding: 7px 28px;
  background-color: #ff5c5c !important;
  border: none;
  outline: none;
  border-radius: 10px;
}
.btn_div1 {
  text-align: center;
  padding: 10px;
}
.btn_div1 Button {
  background-color: #ff5c5c;
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  outline: none;
}
.spinner_div {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container_div {
  height: 59vh;
  overflow-y: scroll;
}
.text-top-15{
  position: relative;
    top: -15px;
}
