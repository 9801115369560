.btn_submit {
  padding: 7px 28px;
  background-color: #ff5c5c !important;
  border: none;
  outline: none;
  border-radius: 10px;
}
.btn_div1 {
  text-align: center;
  padding: 10px;
}
.btn_div1 Button {
  background-color: #ff5c5c;
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  outline: none;
}
.apply {
  border: 4px solid red;
  height: 50vh;
  overflow: scroll;
}
.basic_info {
  /* height: 60%; */
  /* border: 2px solid green; */
}
.register_wrapper {
  width: 100%;
}
