.login_wrapper {
  width: 60%;
  margin: auto;
  display: flex;
  box-shadow: 1px 1px 8px 0px;
  border-radius: 10px;
  padding: 50px 25px;
  justify-content: space-between;
}
.login_logo {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid lightgray;
}
.login_logo img {
  width: 250px;
  height: 250px;
  border-radius: 15px;
}
.login_form {
  width: 60%;
  padding: 10px 50px;
  /* border: 2px solid red; */
}
#pass_div {
  /* border: 2px solid; */
}
#forgot_pass a {
  text-decoration: none;
  color: #ff5c5c;
  font-size: 14px;
}
.btn_div {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.btn_div button {
  color: white;
}
.btn_div Button a {
  color: white;
  text-decoration: none;
}
.btn_div Button {
  padding: 7px 28px;
  background-color: #ff5c5c;
  border: none;
  outline: none;
  border-radius: 10px;
}
.btn_div Button:hover {
  padding: 7px 28px;
  background-color: #ff5c5c;
  border: none;
  outline: none;
  border-radius: 10px;
}
.btn-primary:focus {
  padding: 7px 28px;
  background-color: #ff5c5c !important;
  border: none !important;
  outline: none !important;
  border-radius: 10px;
}
.component_wrapper {
  /* border: 2px solid green; */
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mb-3 {
  width: 90%;
}
Form {
  /* border: 2px solid; */
  width: 80%;
  margin: auto;
}
.form-label {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.modal-footer .btn-primary {
  background-color: #ff5c5c;
  border: none;
  outline: none;
}
.modal-title {
  color: #ff5c5c;
}
.spinner-border {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid currentColor !important;
  border-right-color: transparent !important;
  margin-left: 10px;
}
.login_text {
  display: flex;
  align-items: center;
}
#form-label {
  /* border: 2px solid red; */
  width: 100%;
  margin: auto;
}
#form-header {
  font-size: 20px;
  display: block !important;
  text-align: center;
}
.OTP-INPUT{
  display: block !important;
  text-align: center;
}
input[type="tel"]:last-child {
  margin-right: 0px !important;
}