#home_logout {
  padding: 7px 15px;
  background-color: #ff5c5c;
  border: none;
  outline: none;
  border-radius: 10px;
  color: white;
  float: right;
  margin: 10px;
}
#homeContainer {
}

/* .btnmy{width: 100%;} */
.table {
  text-align: center;
}

button.mybtnP {
  background-color: #595858;
  border: none;
  border-radius: 25px;
  padding: 0px 20px;
  color: white;
}

#homeContainer .mr-2 {
  margin-right: 10px;
  float: right;
}
.table tr {
  line-height: 35px;
  box-shadow: rgb(0 0 0 / 45%) 0px 25px 20px -20px;
}
.main-div{width: 80%; margin: auto; padding: 20px; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}
.tablediv{width: 100%;}
.table-bordered td, .table-bordered th {
  border: none!important; 
}
#homeContainer {
  text-align: center;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #b13464;
  border-bottom: 1pxsolidrgba(0,0,0,.125);
  color: white;
  text-align: left;
}
.card-title {
  margin-bottom: 0.75rem;
  color: #7a1060;
}
p.card-text {
  color: #db6266;

}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #b13464!important;
}
a#home-tab {
  color: #dd6266;
}
a#profile-tab {
  color: #dd6266;
}
@media screen and (max-width: 400px) {
  .table {
    font-size: 13px;
   
}
form.login_form_front input {
 
  height: 49px;
}
.btn {
  font-size: 13px;
}
button.mybtnP {
  /* padding: 5px 15px!important; */
}
.main-div {
  width: 90%;
  margin: auto;
  padding: 8px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
 
}
.tablediv{width: 100%; overflow-x: scroll;}
}

.card {
  /* box-shadow: rgb(149 157 165 / 20%) 0px 4px 12px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px;
  border: none;
  border-radius: 25px;
}
html {
  scroll-behavior: smooth;
}

button.closebtnmodal.btn.btn-primary {
  padding: 0px 12px !important;
  background-color: #807e7e !important;
  border: none !important;
  outline: none !important;
  border-radius: 25px;
  /* box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%); */
}
