Form {
  /* border: 2px solid; */
  padding: 20px;
}
.btn_submit {
  padding: 7px 28px;
  background-color: #ff5c5c !important;
  border: none;
  outline: none;
  border-radius: 10px;
}
.btn_div1 {
  text-align: center;
  padding: 10px;
}
.btn_div1 Button {
  background-color: #ff5c5c;
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  outline: none;
}
.background {
  height: 59vh;
  overflow-y: scroll;
}
/* ::-webkit-scrollbar {
  display: none;
} */
.spinner_div {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#spinner {
  font-size: 550px;
}
/* .form-check-input[type=checkbox] {
  width: 35px;
  border-radius: 30px;
  height: 20px;
  
} */
.lebelcheck label{padding-left:15px;}
.form-check-input[type=checkbox] {
  width: 24px!important;
  height: 15px!important;
  border-radius: 25px!important;
  border: 2px solid black!important;
} 

.container {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 32px;
  background-color: #fff;
  border-radius: 25px;
  border: 2px solid #000;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #fb9568;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 12px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}