body {
  margin: 0;

  font-family: Roboto, Helvetica, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #dbadebbf!important; */
  background: #F4EFEC!important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* td {
  text-align: left;
  vertical-align: middle;
}

th {
  text-align: left !important;
  vertical-align: middle !important;
} */
